import React from "react";
import "./styles/mainfooter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  // faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const childCard = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const parentContainer = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.4,
    },
  },
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <motion.div
          variants={parentContainer}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
            amount: 0.3,
          }}
          className="footer-content"
        >
          {/* Logo and Quote Section */}
          <div className="footer-section logo-quote">
            <img
              src="/images/vsm-logo.jpeg"
              // src="/images/vsmlog-logo.jpg"
              alt="Company Logo"
              className="company-logo"
            />
            <p className="footer-quote">
              "Connecting the world with seamless logistics solutions, where
              reliability meets efficiency."
            </p>
          </div>

          {/* Pages Section */}
          <motion.div variants={childCard} className="footer-section pages">
            <h5>Pages</h5>
            <ul>
              <li>
                {"> "} <Link to="/">Home</Link>
              </li>
              <li>
                {">"} <Link to="/aboutus">About Us</Link>
              </li>
              <li>
                {">"} <Link to="/service/services">Services</Link>
              </li>
              <li>
                {">"} <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </motion.div>

          {/* Find Us Section */}
          <motion.div variants={childCard} className="footer-section find-us">
            <h5>Find Us</h5>
            <ul>
              <li>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                New Hafeezpet, Plot No 154, Premnagar-B Block, Serilingampally
                Hyderabad, Telangana, 500049 India
              </li>
              <li>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <a href="tel:(+91)9989394150">(+91)9989394150</a>
              </li>
              <li>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <a
                  className="company-mail"
                  href="mailto:saradhivsmlogistics@gmail.com"
                >
                  saradhivsmlogistics@gmail.com
                </a>
              </li>
            </ul>
            <div className="social-media">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </motion.div>

          {/* Services Section */}
          <motion.div variants={childCard} className="footer-section services">
            <h5>Services</h5>
            <ul>
              <li>
                {"> "}{" "}
                <Link to="/service/air-customs-clearance">
                  Air Customs Clearance
                </Link>
              </li>
              <li>
                {"> "}{" "}
                <Link to="/service/ocean-customs-clearance">
                  Ocean Customs Clearance
                </Link>
              </li>
              <li>
                {"> "}{" "}
                <Link to="/service/land-customs-clearance">
                  Land Customs Clearance
                </Link>
              </li>
              <li>
                {"> "}{" "}
                <Link to="/service/train-customs-clearance">
                  Train Customs Clearance
                </Link>
              </li>
              <li>
                {"> "}{" "}
                <Link to="/service/air-and-ocean-freight">
                  Air & Ocean Freight
                </Link>
              </li>
              <li>
                {"> "} <Link to="/service/transportation">Transportation</Link>
              </li>
            </ul>
          </motion.div>
        </motion.div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; 2024 VSMLogistics Company. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
