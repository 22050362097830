import React, { useRef } from "react";
import "./SelectDropdown.css";

const SelectDropdown = ({ serviceType, setServiceType ,setFieldError }) => {
  const dropRef = useRef(null);
  const containerRef = useRef(null);
  let toggle = false;

  // Function to toggle dropdown visibility
  function dropdown() {
    toggle = !toggle;
    const item = dropRef.current;
    item.style.display = toggle ? "block" : "none";

    if (toggle) {
      // Add event listener when dropdown is open
      document.addEventListener("click", handleClickOutside);
    } else {
      // Remove event listener when dropdown is closed
      document.removeEventListener("click", handleClickOutside);
    }
  }

  // Function to close dropdown and set selected item
  function closeDropdown(option) {
    setServiceType(option);
    setFieldError((prev) => ({ ...prev, serviceType: false }))
    const item = dropRef.current;
    item.style.display = "none";
    toggle = false;
    document.removeEventListener("click", handleClickOutside);
  }

  // Function to handle clicks outside the dropdown
  function handleClickOutside(event) {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      const item = dropRef.current;
      item.style.display = "none";
      toggle = false;
      document.removeEventListener("click", handleClickOutside);
    }
  }

  return (
    <div className="select-dropdown" ref={containerRef}>
      <div className="selected-item" onClick={dropdown}>
        {serviceType === "" ? "Select A Service" : serviceType}
      </div>
      {/* Dropdown options */}
      <ul className="select-options" id="options" ref={dropRef}>
        <li
          onClick={() => {
            closeDropdown("Imports Customs Clearance");
          }}
          className={
            serviceType === "Imports Customs Clearance" ? "selected-active" : ""
          }
        >
          Imports Customs Clearance
        </li>
        <li
          onClick={() => {
            closeDropdown("Exports Customs Clearance");
          }}
          className={
            serviceType === "Exports Customs Clearance" ? "selected-active" : ""
          }
        >
          Exports Customs Clearance
        </li>
        <li
          onClick={() => {
            closeDropdown("Transportation");
          }}
          className={serviceType === "Transportation" ? "selected-active" : ""}
        >
          Transportation
        </li>
        <li
          onClick={() => {
            closeDropdown("Freight Forwarding");
          }}
          className={serviceType === "Freight Forwarding" ? "selected-active" : ""}
        >
          Freight Forwarding
        </li>
        <li
          onClick={() => {
            closeDropdown("Others");
          }}
          className={serviceType === "Others" ? "selected-active" : ""}
        >
          Others
        </li>
      </ul>

      {/* Down arrow */}
      <div className="down-arrowhead">&#8964;</div>
    </div>
  );
};

export default SelectDropdown;
