import React from "react";
import "./styles/ourachievements.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile,
  faCalendarAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const childCard = {
  initial: { opacity: 0, y: 40 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const parentContainer = {
  initial: { opacity: 0, y: 30 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.4,
      when: "beforeChildren",
    },
  },
};

const OurAchievements = () => {
  const achievements = [
    {
      icon: faSmile,
      title: "100%",
      subtitle: "Client Satisfaction",
      description: "Delivering excellence and ensuring client happiness.",
      animationDelay: "0s",
    },
    {
      icon: faCalendarAlt,
      title: "10+",
      subtitle: "Years of Experience",
      description: "A proven track record of industry expertise.",
      animationDelay: "0.2s",
    },
    {
      icon: faUsers,
      title: "30+",
      subtitle: "Clients Worldwide",
      description: "Trusted by numerous clients across the globe.",
      animationDelay: "0.4s",
    },
  ];

  return (
    <section className="achievements text-center">
      <motion.div
      variants={parentContainer}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
       className="container">
        <h2>Our Achievements</h2>
        <div className="row justify-content-center">
          {achievements.map((achievement, index) => (
            <motion.div variants={childCard} key={index} className="col-md-4 mb-4">
              <div className={"achievement-card p-4 shadow-sm"}>
                <div className="icon-container mb-3">
                  <FontAwesomeIcon icon={achievement.icon} className="icon" />
                </div>
                <h3 className="achievement-title">{achievement.title}</h3>
                <h4 className="achievement-subtitle">{achievement.subtitle}</h4>
                <p className="achievement-description">
                  {achievement.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default OurAchievements;
