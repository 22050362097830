import React from "react";
import { motion } from "framer-motion";

const parentContainer = {
  initial: { opacity: 0, x: -10 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: 0.2,
      staggerChildren: 0.2,
      when: "beforeChildren",
    },
  },
};

const childLi = {
  initial: { opacity: 0, y: -10 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const OurValues = () => {
  return (
    <motion.section
      variants={parentContainer}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      className="our-values-section ourmethods"
    >
      <h3>Our Values</h3>
      <ul>
        <motion.li variants={childLi}>
          <strong>Integrity:</strong> Operating with honesty and transparency in
          all our dealings, ensuring ethical practices and building lasting
          trust with our clients.
        </motion.li>
        <motion.li variants={childLi}>
          <strong>Reliability:</strong> Our commitment to dependability means you
          can trust us to deliver your goods on time, every time, with no
          surprises.
        </motion.li>
        <motion.li variants={childLi}>
          <strong>Commitment:</strong> We are dedicated to delivering
          exceptional service and support.
        </motion.li>
        <motion.li variants={childLi}>
          <strong>Collaboration:</strong> Working closely with our partners and
          clients to foster strong relationships and achieve shared goals
          effectively.
        </motion.li>
        <motion.li variants={childLi}>
          <strong>Safety:</strong> Ensuring the highest standards of safety for
          your cargo and our team, maintaining rigorous safety protocols and
          training.
        </motion.li>
      </ul>
    </motion.section>
  );
};

export default OurValues;
