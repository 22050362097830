import React from "react";
import "./styles/WeAreBestAt.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox , faUserLock ,faTruckFast} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const childCard = {
  initial: { opacity: 0, y: 40 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const parentContainer = {
  initial: { opacity: 0, y: 30 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.4,
      when: "beforeChildren",
    },
  },
};

const WeAreBestAt = () => {
  return (
    <div className="we-are-best-at-section">
      <motion.div
        variants={parentContainer}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className="container text-center"
      >
        <h2 className="section-title">We Are Best At</h2>
        <p className="section-subtitle">Excellence in Logistics and Beyond</p>
        <div className="row">
          {/* Card 1: Secure Handling */}
          <motion.div variants={childCard} className="col-md-4">
            <div className="best-card">
              <FontAwesomeIcon icon={faBox} size="3x" className="icon" />
              <h3 className="card-title">Secure Handling</h3>
              <p className="card-text">
                Your cargo is handled with utmost care and precision
              </p>
            </div>
          </motion.div>

          {/* Card 2: Fast Service */}
          <motion.div variants={childCard} className="col-md-4">
            <div className="best-card">
              <FontAwesomeIcon icon={faTruckFast} size="4x" className="icon" />
              <h3 className="card-title">Fast Service</h3>
              <p className="card-text">
                Quick response times and efficient problem-solving.
              </p>
            </div>
          </motion.div>

          {/* Card 3: Trustworthy Partner */}
          <motion.div variants={childCard} className="col-md-4">
            <div className="best-card">
              <FontAwesomeIcon icon={faUserLock} size="3x" className="icon" />
              <h3 className="card-title">Trustworthy Partner</h3>
              <p className="card-text">
                Building long-term relationships with transparency.
              </p>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default WeAreBestAt;
