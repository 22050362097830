import {
  faCircleQuestion,
  faMapMarkerAlt,
  // faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import "./ContactForm.css";
import axios from "axios";
import { motion } from "framer-motion";
import SelectDropdown from "./SelectDropdown";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const constraintsRef = useRef(null);
  const [fieldError, setFieldError] = useState({
    name: false,
    companyName: false,
    email: false,
    phone: false,
    subject: false,
    serviceType: false,
    message: false,
  });

  const scrollToForm = () => {
    document
      .getElementById("contactUsForm") // id of contact-form-section
      .scrollIntoView({ behavior: "smooth" });
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setResponse("");
    setError("");

    // form validation
    const errors = {
      name: name === "",
      companyName: companyName === "",
      email: email === "" || !/^\S+@\S+\.\S+$/.test(email),
      phone: phone === "" || !/^\d{10}$/.test(phone),
      subject: subject === "",
      serviceType: serviceType === "",
      message: message === "",
    };

    setFieldError(errors);

    // If there are errors, don't submit the form
    if (Object.values(errors).some((error) => error)) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/contact/post-contact`,
        { name, companyName, email, phone, subject, serviceType, message }
      );

      if (data?.success) {
        setLoading(false);
        setResponse(data?.message);

        // reset inputs
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        setCompanyName("");
        setServiceType("");
      } else {
        setLoading(false);
        setError(data?.error);
        console.log(data?.error);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setError("something went wrong in sending contact/quote details");
      console.log("something went wrong in sending contact/quote details");
    }
  };

  return (
    <div className="contact-form">
      <div className="container">
        <div className="contact-form-top row">
          <div className="col-md-8 contact-form-top-content">
            <span className="contact-form-top-subtitle">REQUEST A QUOTE</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              Request your <span className="subtitle-tag">quote</span> today by
              contacting us directly.
            </motion.h2>
          </div>
          <div className="col-md-4 contact-form-tag-button">
            <motion.button
              className="drag-button"
              ref={constraintsRef}
              style={{ overflow: "hidden", cursor: "auto" }}
            >
              <motion.div
                drag
                dragConstraints={constraintsRef}
                className="drag-content"
              >
                Contact
              </motion.div>
            </motion.button>
          </div>
        </div>
      </div>
      {/* Contact 0r Quote form */}
      <div className="contact-form-grid">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          viewport={{
            once: true,
            amount: 0.3,
          }}
          className="contact-form-wrapper"
          id="contactUsForm"
        >
          <form className="contact-form-card" onSubmit={handleContactSubmit}>
            <div className="inputs-grid">
              <div>
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setFieldError((prev) => ({ ...prev, name: false }));
                  }}
                  id="name"
                  placeholder="Enter Your Name"
                />
                <div className="field-error">
                  {fieldError.name && "*This field is required"}
                </div>
              </div>
              <div>
                <label htmlFor="compname">Company Name</label>
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                    setFieldError((prev) => ({ ...prev, companyName: false }));
                  }}
                  id="compname"
                  placeholder="Enter Company Name"
                />
                <div className="field-error">
                  {fieldError.companyName && "*This field is required"}
                </div>
              </div>
              <div>
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setFieldError((prev) => ({ ...prev, email: false }));
                  }}
                  id="email"
                  placeholder="Enter Your Email"
                  // required
                />
                <div className="field-error">
                  {fieldError.email && "*Please enter a valid email"}
                </div>
              </div>
              <div>
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setFieldError((prev) => ({ ...prev, phone: false }));
                  }}
                  id="phone"
                  placeholder="Enter Your Phone Number"
                />
                <div className="field-error">
                  {fieldError.phone && "*Please enter a valid phone number"}
                </div>
              </div>
              <div>
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setFieldError((prev) => ({ ...prev, subject: false }));
                  }}
                  id="subject"
                  placeholder="Enter Your Query Subject"
                />
                <div className="field-error">
                  {fieldError.subject && "*This field is required"}
                </div>
              </div>
              <div>
                <div className="servicetype">Choose a Service</div>
                <div>
                  <SelectDropdown
                    serviceType={serviceType}
                    setServiceType={setServiceType}
                    setFieldError={setFieldError}
                  />
                </div>
                <div className="field-error">
                  {fieldError.serviceType && "*This field is required"}
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="message">Leave us a message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setFieldError((prev) => ({ ...prev, message: false }));
                }}
                cols={10}
                rows={5}
                placeholder={"Write your message here..."}
              />
              <div className="field-error">
                {fieldError.message && "*This field is required"}
              </div>
            </div>
            <div className="contact-form-button">
              <button type="submit" className="form-submit-button">
                {loading ? "Loading..." : "Send Message -/"}
              </button>
              {response && (
                <span className="contact-response">
                  <span style={{ fontWeight: "bold" }}>Response: </span>
                  {response}
                </span>
              )}
              {error && (
                <span className="contact-error">
                  <span style={{ fontWeight: "bold" }}>Response: </span>
                  {error}
                </span>
              )}
            </div>
          </form>
        </motion.div>
        <div className="contact-form-content">
          <div className="form-inner-content">
            <div className="form-content-card">
              <div className="contact-card-icon">
                <FontAwesomeIcon icon={faCircleQuestion} />
              </div>
              <div className="contact-card-inner-content">
                <motion.h2
                  initial={{ opacity: 0, y: 15 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                >
                  Contact/Quote Form
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 15 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                >
                  We’re glad you’re interested! Complete the form and our team
                  will reach out to discuss how we can assist with your
                  logistics needs and customs clearance from warehousing and
                  distribution to air and ocean freight.
                </motion.p>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={scrollToForm}
                >
                  {/* <span>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                  </span>{" "} */}
                  Complete the Form
                </button>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.3,
              }}
              className="form-content-card address-card"
            >
              <div className="contact-card-icon">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <div className="contact-card-inner-content">
                <h2>Visit Us</h2>
                <p>
                  We welcome you to our office to discuss your logistics needs.
                </p>
                <div className="contact-address">
                  <p style={{ marginBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>Address:</span> New
                    Hafeezpet, Plot No 154, Premnagar-B Block, Serilingampally
                    Hyderabad, Telangana, 500049 India.
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Base Location:
                    </span>{" "}
                    Hyderabad
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="contact-form-content-bg"></div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
