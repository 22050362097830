import React, { useEffect, useRef } from "react";
import './styles/GoToTop.css';

const GoToTop = () => {
  const myButtonRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (myButtonRef.current) { // Add this check
        if (window.scrollY > 500) {
          myButtonRef.current.style.display = "block";
        } else {
          myButtonRef.current.style.display = "none";
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Check scroll position on initial load
    handleScroll();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topFunction = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      onClick={topFunction}
      ref={myButtonRef}
      id="myTopBtn"
      title="Go to top"
      style={{ display: "none" }}
    >
      Top
    </button>
  );
};

export default GoToTop;
