import React from "react";
import "./styles/services.css";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";
import GetAQuote from "../components/singleservices/GetAQuote";
import GoToTop from "../components/buttons/GoToTop";
import { motion } from "framer-motion";

const Services = () => {
  const mainServices = [
    {
      path: "air-customs-clearance",
      name: "Air Customs Clearance",
      description:
        "Our air customs clearance services ensure a smooth and efficient process, minimizing delays and ensuring your shipments clear customs quickly and accurately.",
      backgroundImageUrl: "/images/ourservices/airplane2.jpg",
      iconUrl: "/images/ourservices/airplane-icon2.jpg",
    },
    {
      path: "ocean-customs-clearance",
      name: "Ocean Customs Clearance",
      description:
        "Efficient ocean customs clearance solutions that expedite your maritime shipments and keep your supply chain moving smoothly.",
      backgroundImageUrl: "/images/ourservices/ocean-pic.jpg",
      iconUrl: "/images/ourservices/ship-icon.jpg",
    },
    {
      path: "land-customs-clearance",
      name: "Land Customs Clearance",
      description:
        "Streamlined land customs clearance to ensure your overland shipments are processed quickly and without complications.",
      backgroundImageUrl: "/images/ourservices/land-cc2.jpg",
      iconUrl: "/images/why-us/fast-delivery.webp",
    },
    {
      path: "train-customs-clearance",
      name: "Train Customs Clearance",
      description:
        "Reliable train customs clearance services that keep your rail shipments on track and moving through customs efficiently.",
      backgroundImageUrl: "/images/ourservices/train-pic.jpg",
      iconUrl: "/images/ourservices/train-cargo-icon.jpg",
    },
    {
      path: "air-and-ocean-freight",
      name: "Air & Ocean Freight",
      // name: "Freight & Imports-Exports",
      description:
        "Connecting your business to the world with reliable air and ocean freight solutions.",
      backgroundImageUrl: "/images/ourservices/air-ocean-freight.jpg",
      iconUrl: "/images/ourservices/air-ocean-icon.jpg",
    },
    {
      path: "transportation",
      name: "Transportation",
      description:
        "Comprehensive transportation services to ensure your goods are delivered safely and on time, every time.",
      backgroundImageUrl: "/images/ourservices/truck-transportation.jpg",
      iconUrl: "/images/why-us/fast-delivery.webp",
    },
    {
      path: "import-services",
      name: "Import Services",
      description:
        "Ensuring seamless and efficient import solutions tailored to your business needs.",
      backgroundImageUrl: "/images/ourservices/imports-img.jpeg",
      iconUrl: "/images/ourservices/imports-icon2.jpg",
    },
    {
      path: "export-services",
      name: "Export Services",
      description:
        "Expanding your business horizons with dependable export solutions across the globe.",
      backgroundImageUrl: "/images/ourservices/exports-img.jpeg",
      iconUrl: "/images/ourservices/exports-icon.jpg",
    },
  ];

  const scrollToMainServices = () => {
    document
      .getElementById("mainServices") // id of main-services section
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout
      title={"Services - VSM LOGISTICS"}
      description="Explore our range of logistics services designed to optimize your supply chain. From customs clearance to warehousing and transportation, find out how we can help streamline your operations."
      keywords="logistics services, supply chain solutions, customs clearance, warehousing, transportation, freight management, logistics solutions"
    >
      <section className="main-services-section">
        {/* header-section*/}
        <header className="main-services-header">
          {/* sub-navigation */}
          <div className="header-subnav">
            <Link to={"/"}>Home</Link>
            <span> / </span>
            Services
          </div>
          <div>
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Services
            </motion.h1>
          </div>

          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Trust us to deliver your cargo with precision and care.
          </motion.p>
        </header>

        {/* hero-section */}
        <div className="main-services-top-bg">
          <div className="container">
            <div className="main-services-top">
              <div className="main-service-content">
                <span className="main-services-subtitle">OUR SERVICES</span>
                {/* <Link to={'/'}>Home</Link> */}
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                  className="main-services-title"
                >
                  Simplify your <span className="subtitle-tag">supply</span>{" "}
                  chain with our <span className="subtitle-tag">expert</span>{" "}
                  services.
                </motion.h1>
                <motion.p
                  initial={{ opacity: 0, y: 15 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                >
                  Optimize your logistics with our cutting-edge solutions. Our
                  team ensures timely and reliable delivery, tailored to your
                  unique needs. We handle the complexities so you can focus on
                  growing your business. Experience seamless efficiency and
                  exceptional service with us.
                </motion.p>
                <motion.button
                  initial={{ opacity: 0, y: 15 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                  className="services-triangle-down"
                  onClick={scrollToMainServices}
                >
                  Explore
                </motion.button>
              </div>
            </div>
          </div>
        </div>

        {/* services-cards-section */}
        <div className="main-services-bottom" id="mainServices">
          <div className="container">
            <div className="main-grid-layout">
              {mainServices.map((service, index) => {
                return (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{
                      once: true,
                      amount: 0.4,
                    }}
                    className="main-service-card"
                    key={index}
                  >
                    <Link
                      to={`/service/${service.path}`}
                      className="service-card-wrapper"
                    >
                      <div className="servide-img-wrapper">
                        <div className="service-card-img">
                          <img
                            src={service.backgroundImageUrl}
                            alt={service.name}
                          />
                        </div>
                      </div>
                      <div className="main-service-card-content">
                        <div className="service-card-icon">
                          <img
                            src={service.iconUrl}
                            alt={`${service.name} icon`}
                          />{" "}
                        </div>
                        <div className="service-inner-content">
                          <h3>{`${index + 1}. ${service.name}`}</h3>
                          <p>{service.description}</p>
                          <button className="main-service-button">
                            View More
                          </button>
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>

        {/* get-a-quote-section */}
        <GetAQuote />

        {/* Go-to-top-button */}
        <GoToTop />
      </section>
    </Layout>
  );
};

export default Services;
