import React from "react";
import { motion } from "framer-motion";

const parentContainer = {
  initial: { opacity: 0, x: -10 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: 0.2,
      staggerChildren: 0.2,
      when: "beforeChildren",
    },
  },
};

const childImg = {
  initial: { opacity: 0, x: 10 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const childPara = {
  initial: { opacity: 0, y: -10 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const OurApproach = () => {
  return (
    <section className="our-approach-section ourmethods">
      <motion.div
        variants={parentContainer}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        className="row align-items-center flex-md-row-reverse"
      >
        <motion.div variants={childImg} className="col-md-6 method-imgs">
          <img
            src="/images/about/our-approach2.jpg"
            alt="Our Approach"
            className="img-fluid rounded"
          />
        </motion.div>
        <div className="col-md-6">
          <h3>Our Approach</h3>
          <motion.p variants={childPara}>
            At the heart of our operations is a customer-centric approach. We
            believe that every shipment is more than just a delivery; it's a
            step towards building lasting relationships. Our team leverages
            cutting-edge technology and personalized service to ensure that your
            logistics experience is seamless and stress-free. We take the time
            to understand your business and craft solutions that align with your
            objectives.
          </motion.p>
        </div>
      </motion.div>
    </section>
  );
};

export default OurApproach;
