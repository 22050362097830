import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import "./styles/singleservice.css";
import SingleServiceContent from "../components/singleservices/SingleServiceContent";
import SingleServiceHero from "../components/singleservices/SingleServiceHero";
import SingleServiceHeader from "../components/singleservices/SingleServiceHeader";
import { useNavigate, useParams } from "react-router-dom";
import GoToTop from "../components/buttons/GoToTop";

const SingleServicePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [serviceName, setServiceName] = useState("");

  const services = [
    {
      path: "air-customs-clearance",
      name: "Air Customs Clearance",
    },
    {
      path: "ocean-customs-clearance",
      name: "Ocean Customs Clearance",
    },
    {
      path: "land-customs-clearance",
      name: "Land Customs Clearance",
    },
    {
      path: "train-customs-clearance",
      name: "Train Customs Clearance",
    },
    {
      path: "air-and-ocean-freight",
      name: "Air & Ocean Freight",
    },
    {
      path: "transportation",
      name: "Transportation",
    },
    {
      path: "import-services",
      name: "Import Services",
    },
    {
      path: "export-services",
      name: "Export Services",
    },
  ];

  useEffect(() => {
    // do avoid unnecassary navigation
    const service = services.find((service) => service.path === params.slug);
    if (service) {
      setServiceName(service.name);
      // return;//do nothing
    } else {
      navigate("/page-not-found");
    }
    // eslint-disable-next-line
  }, [params.slug]);

  return (
    <Layout title={`${serviceName} - VSM LOGISTICS`}>
      <section className="single-service-section">
        {/* Header-section */}
        <SingleServiceHeader params={params} />

        {/* Hero-section */}
        <SingleServiceHero params={params} />

        {/* Service-content-section */}
        <SingleServiceContent params={params} />

        {/* Go-to-top-button */}
        <GoToTop />

        {/* single-service-hero-bg */}
        <div className="single-service-hero-bg"></div>
      </section>
    </Layout>
  );
};

export default SingleServicePage;
