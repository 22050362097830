import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

// React encourages the use of JavaScript default parameters for function components.
const Layout = ({
  children,
  title = "VSM LOGISTICS - Your trusted logistics partner.",
  description = "Discover our top-notch logistics solutions designed to optimize your supply chain, from customs clearance to warehousing and transportation. Efficient, reliable, and tailored to your needs.",
  keywords = "logistics, supply chain, customs clearance, warehousing, transportation, logistics solutions, efficient logistics, global shipping, freight management,Vsm logictics,logistics",
  author = "VSM LOGISTICS",
}) => {
  return (
    <HelmetProvider>
      {/* <div> */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="author" content={author} />
          <title>{title}</title>
        </Helmet>
        {/* navbar */}
        <Header />
        {/* body */}
        <main>{children}</main>
        {/* footer */}
        <Footer />
      {/* </div> */}
    </HelmetProvider>
  );
};

export default Layout;
