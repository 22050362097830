import React from "react";
import "./styles/whychooseus.css";
import { motion } from "framer-motion";
import { useRef } from "react";

const childCard = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const parentContainer = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
    },
  },
};

const WhyChooseUs = () => {
  const constraintsRef = useRef(null);

  return (
    <section className="why-choose-us">
      <div className="container">
        <div className="why-us-top row">
          <div className="col-md-9 why-us-top-content">
            <span className="why-us-top-subtitle subtitle">WHY CHOOSE US</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              Choose us for unmatched{" "}
              <span className="subtitle-tag">reliability</span> and exceptional{" "}
              <span className="subtitle-tag">service</span>.
            </motion.h2>
          </div>
          <div className="col-md-3 why-us-tag-button">
            <motion.button
              className="drag-button"
              ref={constraintsRef}
              style={{ overflow: "hidden", cursor: "auto" }}
            >
              <motion.div
                drag
                dragConstraints={constraintsRef}
                className="drag-content"
              >
                Quote
              </motion.div>
            </motion.button>
          </div>
        </div>
        <motion.div
          variants={parentContainer}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
            amount: 0.2,
          }}
          className="features-grid-container"
        >
          <motion.div variants={childCard} className="feature-card feature1">
            <div className="feature-img">
              <img src="/images/why-us/fast-delivery.webp" alt="feature-img" />
            </div>
            <div className="why-us-inner-content">
              <h3 className="feature-heading">Fast Delivery</h3>
              <p className="feature-para">
                Speed and efficiency are at the core of our logistics services.
                We ensure your goods are delivered on time, every time, with the
                highest level of precision and care.
              </p>
            </div>
          </motion.div>
          <motion.div variants={childCard} className="feature-card feature2">
            <div className="feature-img">
              <img
                src="/images/why-us/customer-service-new.webp"
                alt="feature-img"
              />
            </div>
            <div className="why-us-inner-content">
              <h3 className="feature-heading">24/7 Customer Support</h3>
              <p className="feature-para">
                We're here for you around the clock with 24/7 customer support
                to ensure your logistics needs are always met.
              </p>
            </div>
          </motion.div>
          <motion.div variants={childCard} className="feature-card feature3">
            <div className="feature-img">
              {/* <img src="" alt="feature-img" /> */}
              <span className="globe-img">🌍</span>
            </div>
            <div className="why-us-inner-content">
              <h3 className="feature-heading">Global Reach</h3>
              <p className="feature-para">
                With a network that spans the globe, we connect you to every
                corner of the world. Our international expertise ensures
                seamless operations and successful global partnerships.
              </p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
