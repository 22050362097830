import React from "react";
import "./styles/aboutus.css";
import Layout from "../layout/Layout";
import WeAreBestAt from "../components/homepage/WeAreBestAt";
// import SubNavbar from "../components/aboutpage/SubNavbar";
import { motion } from "framer-motion";
import AboutCompany from "../components/aboutpage/AboutCompony";
import OurStrength from "../components/aboutpage/OurStrength";
import OurMethods from "../components/aboutpage/OurMethods";
// import AboutCompany from "../components/aboutpage/AboutCompony";
// import OurTeam from "../components/footerlayout/OurTeam";
import GoToTop from "../components/buttons/GoToTop";
import { Link } from "react-router-dom";
import OurFounder from "../components/buttons/OurFounder";

const AboutUs = () => {
  return (
    <Layout
      title={"About - VSM LOGISTICS"}
      description="Learn more about our company’s mission, values, and expertise in providing top-quality logistics solutions. Discover how we deliver excellence in supply chain management and customer satisfaction."
      keywords="about us, logistics company, supply chain management, logistics expertise, company values, logistics services, customer satisfaction"
    >
      <div className="about-us-container">
        <header className="about-us-header">
          {/* sub-navigation */}
          <div className="header-subnav">
            <Link to={"/"}>Home</Link>
            <span> / </span>
            About
          </div>
          <div>
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              About Us
            </motion.h1>
          </div>

          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Discover how we make logistics easy, efficient, and effective for
            you.
          </motion.p>
        </header>

        <AboutCompany />

        <OurStrength />

        {/* <div className="container" id="ourMethods">
          <SubNavbar />
        </div> */}

        <OurMethods />

        {/* <OurTeam /> */}
        <OurFounder />

        <footer className="about-us-footer text-center">
          <motion.p
            initial={{ opacity: 0, x: -30 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{
              once: true,
            }}
          >
            Join us on our journey to make logistics better for everyone.
          </motion.p>
        </footer>

        {/* Go-to-top-button */}
        <GoToTop />
      </div>
      <WeAreBestAt />
    </Layout>
  );
};

export default AboutUs;
