import React from "react";
import "./styles/AboutusPreview.css";
import HomeButton from "../buttons/HomeButton";
import { motion } from "framer-motion";

const AboutusPreview = () => {
  return (
    <div className="aboutus-preview-section">
      <div className="about-preview-grid-layout">
        <motion.div
          initial={{ opacity: 0, x: -15 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4 }}
          viewport={{
            once: true,
            amount: 0.2,
          }}
          className="preview-img"
        >
          <img
            src="/images/home/aboutus-prev-img.jpg"
            alt="aboutus-preview-img"
          />
        </motion.div>
        <div className="preview-content">
          <div className="inner-content">
            <span className="preview-subtitle subtitle">ABOUT US</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="preview-heading"
            >
              Your <span className="preview-tag">trusted</span> partner in
              seamless logistics solutions.
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="preview-para"
            >
              We are a leading logistics company dedicated to providing
              efficient and reliable transportation solutions worldwide. Our
              commitment to excellence ensures that your goods are delivered on
              time, every time.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="preview-buttons"
            >
              <HomeButton path={"/aboutus"}>Learn More</HomeButton>
            </motion.div>
          </div>
          <div className="preview-content-bg"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutusPreview;
