import React from "react";
import { motion } from "framer-motion"

const parentContainer = {
  initial: { opacity: 0 , x:-10 },
  animate: {
    opacity: 1, x:0,
    transition: {
      duration: 0.3,
      delay:0.2,
      staggerChildren: 0.3,
      when: "beforeChildren",
    },
  },
};

const childImg = {
  initial: { opacity: 0 , x:10},
  animate: {
    opacity: 1, x:0,
    transition: {
      duration: 0.3,
    },
  },
}

const childPara = {
  initial: { opacity: 0 , y:-10},
  animate: {
    opacity: 1, y:0,
    transition: {
      duration: 0.3,
    },
  },
}

const OurGoal = () => {
  return (
    <section className="our-goal-section ourmethods">
      <motion.div
        variants={parentContainer}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
       className="row align-items-center">
        <motion.div variants={childImg} className="col-md-6 method-imgs">
          <img
            src="/images/about/ourgoal.jpeg"
            alt="Our Goal"
            className="img-fluid rounded"
          />
        </motion.div>
        <div className="col-md-6">
          <h3>Our Goal</h3>
          <motion.p variants={childPara}>
            Our goal is to redefine what’s possible in the world of logistics.
            We aim to become your trusted partner by consistently exceeding your
            expectations through reliable and innovative services. We are
            dedicated to making logistics simpler and more efficient, allowing
            you to focus on growing your business with confidence.
          </motion.p>
        </div>
      </motion.div>
    </section>
  );
};

export default OurGoal;
