import React from "react";
import Layout from "../layout/Layout";
import "./styles/home.css";
// import HomeScreen from "../components/homepage/HomeScreen";
import OurServices from "../components/homepage/OurServices";
import WhyChooseUs from "../components/homepage/WhyChooseUs";
import OurAchievements from "../components/homepage/OurAchievements";
import AboutusPreview from "../components/homepage/AboutusPreview";
import GetInTouchPreview from "../components/footerlayout/GetInTouchPreview";
import HeroSection from "../components/homepage/HeroSection";
import GoToTop from "../components/buttons/GoToTop";
import HomeScreenSlide from "../components/buttons/HomeScreenSlide";

const Home = () => {
  
  return (
    <Layout>
      <div className="layout home">
        {/* <HomeScreen /> */}
        {/* new-design */}
        <HomeScreenSlide />

        <HeroSection />
        <AboutusPreview />
        <OurServices />
        <WhyChooseUs />
        <GetInTouchPreview />

        {/* old-design */}
        <OurAchievements />

        {/* Go-to-top-button */}
        <GoToTop />
      </div>
    </Layout>
  );
};

export default Home;
