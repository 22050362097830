import React from "react";
import "./styles/aboutcompany.css";
import HomeButton from "../buttons/HomeButton";
import { motion } from "framer-motion";

const AboutCompany = () => {
  const scrollToStrength = () => {
    document
      .getElementById("ourStrength") // id of our-strenth section
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="about-table">
      <div className="container about-company-story-section py-5">
        {/* First Row */}
        <div className="row align-items-center first-row">
          <div className="col-md-5 text-center text-md-start mb-3 mb-md-0">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              About Our <span className="our-company">Company</span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              VSM Logistics was founded in the Year 2014 with a passion for
              excellence , commitment and progress, our logistics company has been at the
              forefront of transforming the logistics landscape.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              <HomeButton path={"/contact"}>Contact Us</HomeButton>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, x: -15 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{
              once: true,
              amount: 0.4,
            }}
            className="col-md-7"
          >
            <img
              src="/images/about/about-company-airplane.jpg"
              alt="About our company"
              className="img-fluid"
            />
          </motion.div>
        </div>

        <motion.div
          initial={{ scale: 0.2 }}
          whileInView={{ scale: 1.6 }}
          transition={{ duration: 1.2, delay: 0.5 }}
          viewport={{
            once: true,
            amount: 0.3,
          }}
          className="hero-card"
        ></motion.div>

        {/* Second Row */}
        <div className="row align-items-center mb-4">
          <div className="col-md-6 story-img">
            <img
              src="/images/about/our-story-ship.jpg"
              alt="Our Story"
              className="img-fluid"
            />
          </div>
          <div
            id="storySection"
            className="col-md-6 text-center text-md-start mb-3 mb-md-0"
          >
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              Our <span className="our-company">Story</span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              Our journey from a small operation to an industry leader is built
              on a foundation of commitment, dedication, and an unwavering focus
              on delivering excellence and customer satisfaction. Connecting
              global markets, we specialize in seamless imports and exports from
              India, ensuring efficient and reliable logistics solutions
              worldwide.
            </motion.p>
            <button className="aboutus-more-button" onClick={scrollToStrength}>
              Learn More <span className="down-arrow">&darr;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
