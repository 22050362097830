import React from "react";
import "./styles/HeroSection.css";
import HomeButton from "../buttons/HomeButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";


const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className="home-hero-section">
      <div className="container">
        <div className="home-grid-layout">
          <div className="hero-content">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2, // Trigger animation when 20% of the element is visible
                // margin: "20px 0px 0px 0px", // 20px from the bottom
              }}
              className="hero-heading"
            >
              We start with a <span className="hero-quotes">promise</span>, and
              end with your <span className="hero-quotes">success</span>.
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2, // Trigger animation when 20% of the element is visible
              }}
              className="hero-para"
            >
              Our company begins by understanding your specific logistics needs,
              and we consistently go beyond expectations to ensure every
              delivery reflects our dedication to excellence.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2, // Trigger animation when 20% of the element is visible
              }}
              className="hero-buttons"
            >
              <HomeButton path={"/contact"}>Get A Quote</HomeButton>
              <button
                className="btn btn-outline-primary"
                style={{
                  fontWeight: 600,
                  padding: "16px 36px",
                  fontSize: "18px",
                }}
                onClick={() => navigate("/service/services")}
              >
                Our Services
              </button>
            </motion.div>
          </div>
          <div className="hero-img">
            <img src="/images/home/home-hero-img.jpg" alt="hero-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
