import React from "react";
import Layout from "../layout/Layout";
import "./styles/contactus.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMailBulk,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/contactpage/ContactForm";
import GoToTop from "../components/buttons/GoToTop";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <Layout
      title={"Contact - VSM LOGISTICS"}
      description="Have questions or need support? Contact us to get personalized assistance from our logistics experts. We're here to help with all your supply chain and logistics needs."
      keywords="contact us, logistics support, customer service, logistics experts, get in touch, supply chain assistance, contact logistics company"
    >
      <section className="contact-us-section">
        {/* header-section*/}
        <header className="contact-us-header">
          {/* sub-navigation */}
          <div className="header-subnav">
            <Link to={"/"}>Home</Link>
            <span> / </span>
            Contact
          </div>
          <div>
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Contact Us
            </motion.h1>
          </div>
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Your logistics solution starts here. Reach out to us.
          </motion.p>
        </header>

        {/* hero-section */}
        <div className="contact-us-top-bg">
          <div className="container">
            <div className="contact-us-top">
              <div className="contact-us-content">
                <span className="contact-us-subtitle">GET IN TOUCH</span>
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                  className="contact-us-title"
                >
                  Contact us <span className="subtitle-tag">Today</span>
                </motion.h1>
                <motion.p
                  initial={{ opacity: 0, y: 15 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  viewport={{
                    once: true,
                    amount: 0.2, // 20% of element = animation starts
                  }}
                >
                  Reach out today and discover how we can optimize your
                  logistics operations. We're here to provide expert support and
                  guidance.
                </motion.p>
              </div>
            </div>
          </div>
        </div>

        {/* contact-section- email & phone */}
        <div className="contact-cards-grid container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{
              once: true,
              amount: 0.3,
            }}
            className="email-card contact-card"
          >
            <div className="contact-icon">
              <FontAwesomeIcon icon={faMailBulk} />
            </div>
            <div className="email-inner-content">
              <h2>Send us an Email</h2>
              <p>
                Reach out to us via email for any inquiries or assistance with
                your logistics needs. We're here to help!
              </p>
              <a href="mailto:saradhivsmlogistics@gmail.com">
                saradhivsmlogistics@gmail.com{" "}
                <span>
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </span>
              </a>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{
              once: true,
              amount: 0.3,
            }}
            className="phone-card contact-card"
          >
            <div className="contact-icon">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="phone-inner-content">
              <h2>Give us a Call</h2>
              <p>
                Call us directly for immediate assistance or to discuss your
                logistics needs with one of our experts.
              </p>
              <a href="tel:(+91)9989394150">
                (+91)9989394150{" "}
                <span>
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </span>
              </a>
            </div>
          </motion.div>
        </div>

        {/* contact-form-section */}
        <ContactForm />

        {/* Go-to-top-button */}
        <GoToTop />

        {/* contactus-hero-bg */}
        <div className="contactus-hero-bg"></div>

      </section>
    </Layout>
  );
};

export default ContactUs;
