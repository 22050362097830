import React from "react";
import { motion } from "framer-motion";

const parentContainer = {
  initial: { opacity: 0, x: -10 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: 0.2,
      staggerChildren: 0.3,
      when: "beforeChildren",
    },
  },
};

const childImg = {
  initial: { opacity: 0, x: 10 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const childPara = {
  initial: { opacity: 0, y: -10 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const OurMission = () => {
  return (
    <section className="our-mission-section ourmethods">
      <motion.div
        variants={parentContainer}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        className="row align-items-center flex-md-row-reverse"
      >
        <motion.div variants={childImg} className="col-md-6 method-imgs">
          <img
            src="/images/about/our-mission-cargo.jpg"
            alt="Our Mission"
            className="img-fluid rounded"
          />
        </motion.div>
        <div className="col-md-6">
          <h3>Our Mission</h3>
          <motion.p variants={childPara}>
            Our mission is to connect people and businesses through seamless
            logistics solutions that empower growth and success. We are
            committed to maintaining the highest standards of integrity and
            excellence in all our operations, ensuring that every customer
            receives exceptional value and service.
          </motion.p>
        </div>
      </motion.div>
    </section>
  );
};

export default OurMission;
