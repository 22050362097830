import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SingleServiceHeader = ({ params }) => {
  const [headerData, setHeaderData] = useState({});

  const services = [
    {
      path: "air-customs-clearance",
      name: "Air Customs Clearance",
      quote:
        "Ensuring smooth and timely air customs processing for your shipments.",
    },
    {
      path: "ocean-customs-clearance",
      name: "Ocean Customs Clearance",
      quote:
        "Streamlining ocean freight customs to keep your cargo moving efficiently.",
    },
    {
      path: "land-customs-clearance",
      name: "Land Customs Clearance",
      quote:
        "Efficient land customs solutions for quick and hassle-free overland shipments.",
    },
    {
      path: "train-customs-clearance",
      name: "Train Customs Clearance",
      quote:
        "Reliable customs clearance for rail shipments, keeping your cargo on track.",
    },
    {
      path: "air-and-ocean-freight",
      name: "Air & Ocean Freight",
      quote:
        "Seamless air and ocean freight solutions for global efficiency and reliability.",
    },
    {
      path: "transportation",
      name: "Transportation",
      quote:
        "Comprehensive transportation services for safe and timely deliveries.",
    },
    {
      path: "import-services",
      name: "Import Services",
      quote:
        "Bringing the world to your doorstep, one shipment at a time.",
    },
    {
      path: "export-services",
      name: "Export Services",
      quote:
        "Taking your business to the world with every delivery.",
    },
  ];

  useEffect(() => {
    const service = services.find((service) => service.path === params.slug);
    if (service) {
      setHeaderData(service);
    } else {
      setHeaderData({});
    }
    // eslint-disable-next-line
  }, [params.slug]);

  return (
    <header className="single-services-header">
      {/* sub-navigation */}
      <div className="header-subnav">
        <Link to={"/"}>Home</Link>
        <span> / </span>
        <Link to={"/services"}>Services</Link>
        <span> / </span>
        {headerData?.name}
      </div>
      <div className="single-service-h1">
        <motion.h1
          key={`${params.slug}-1`} // Unique key based on the route
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {headerData?.name}
        </motion.h1>
      </div>
      <motion.p
        key={`${params.slug}-2`}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        {headerData?.quote}
      </motion.p>
    </header>
  );
};

export default SingleServiceHeader;
