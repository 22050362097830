import React from "react";
import "./styles/HomeButton.css";
import { Link } from "react-router-dom";

const HomeButton = ({ children, path }) => {
  return (
    <Link to={path} className="home-button">
      {children} 
      {/* <span className="north-east-arrow">/</span> */}
    </Link>
  );
};

export default HomeButton;
