import React from "react";
import "./styles/ourservices.css";
import { Link } from "react-router-dom";
import HomeButton from "../buttons/HomeButton";
import { motion } from "framer-motion";
import { useRef } from "react";

const childCard = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const parentContainer = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
    },
  },
};

const OurServices = () => {
  const constraintsRef = useRef(null);

  const servicesPreview = [
    {
      name: "Air Customs Clearance",
      quote: "Taking Your Cargo to New Heights with our Customs Clearance.",
      imageUrl: "/images/ourservices/air-pic.jpg",
      path: "/service/air-customs-clearance",
    },
    {
      name: "Ocean Customs Clearance",
      quote: "Efficient and Cost-Effective Shipping Solutions Across the Seas.",
      imageUrl: "/images/ourservices/ocean-pic.jpg",
      path: "/service/ocean-customs-clearance",
    },
    {
      name: "Land Customs Clearance",
      quote:
        "Efficient Road Customs Clearance for Seamless Door-to-Door Delivery.",
      imageUrl: "/images/ourservices/land-pic.jpg",
      path: "/service/land-customs-clearance",
    },
  ];

  return (
    <div className="our-services-preview">
      <div className="container">
        <div className="services-top row">
          <div className="col-md-8 service-content">
            <span className="services-subtitle subtitle">OUR SERVICES</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              We Provide <span className="subtitle-tag">Safe</span> and Reliable
              Logistics Solutions
            </motion.h2>
          </div>
          <div className="col-md-4 service-tag-button">
            <motion.button
              className="drag-button"
              ref={constraintsRef}
              style={{ overflow: "hidden",cursor:"auto" }}
            >
              <motion.div
                drag
                dragConstraints={constraintsRef}
                className="drag-content"
              >
                Trust
              </motion.div>
            </motion.button>
          </div>
        </div>
        <motion.div
          variants={parentContainer}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
            amount: 0.2,
          }}
          className="service-cards"
        >
          {servicesPreview.map((member, index) => (
            <motion.div
              variants={childCard}
              className="service-card"
              key={index}
            >
              <div className="service-card-image">
                <img src={member.imageUrl} alt={`${member.title}`} />
              </div>
              <div className="service-card-content">
                <h3>{member.name}</h3>
                <p>"{member.quote}"</p>
              </div>
              <Link to={member.path} className="service-card-button">
                Learn More
              </Link>
            </motion.div>
          ))}
        </motion.div>
        <motion.div
          variants={childCard}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
            amount: 0.2,
          }}
          className="our-service-buttons"
        >
          <HomeButton path={"/contact"}>Get A Quote</HomeButton>
          <Link to={"/service/services"} className="service-explore-button">
            Explore All Services
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default OurServices;
