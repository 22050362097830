import React from "react";
import "./styles/OurMethods.css";
import { NavLink, Outlet } from "react-router-dom";
import { motion } from "framer-motion";

const OurMethods = () => {
  return (
    <section className="our-methods-section" id="ourMethods">
      <div className="container">
        <div className="methods-top">
          <div className="methods-top-content">
            <span className="methods-top-subtitle">OUR METHODS</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              See how our methods ensure{" "}
              <span className="subtitle-tag">success</span> every step of the
              way<span className="right-down-arrow">&#8628;</span>
            </motion.h2>
          </div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4,delay:0.2 }}
            viewport={{
              once: true,
              amount: 0.3,
            }}
            className="methods-subnavbar-buttons"
          >
            <NavLink
              to={"/aboutus"}
              end
              className={({ isActive }) =>
                isActive ? "method-active" : "method-button"
              }
            >
              Our Approach
            </NavLink>
            <NavLink
              to={"/aboutus/ourgoal"}
              className={({ isActive }) =>
                isActive ? "method-active" : "method-button"
              }
            >
              Our Goal
            </NavLink>
            <NavLink
              to={"/aboutus/ourmission"}
              className={({ isActive }) =>
                isActive ? "method-active" : "method-button"
              }
            >
              Our Mission
            </NavLink>
            <NavLink
              to={"/aboutus/ourvalues"}
              className={({ isActive }) =>
                isActive ? "method-active" : "method-button"
              }
            >
              Our Values
            </NavLink>
          </motion.div>
        </div>
        <div className="methods-bottom">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default OurMethods;
