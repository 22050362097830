import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./styles/header.css";

const Header = () => {
  const services = [
    {
      path: "air-customs-clearance",
      name: "Air Customs Clearance",
    },
    {
      path: "ocean-customs-clearance",
      name: "Ocean Customs Clearance",
    },
    {
      path: "land-customs-clearance",
      name: "Land Customs Clearance",
    },
    {
      path: "train-customs-clearance",
      name: "Train Customs Clearance",
    },
    {
      path: "air-and-ocean-freight",
      name: "Air & Ocean Freight",
    },
    {
      path: "transportation",
      name: "Transportation",
    },
    {
      path: "import-services",
      name: "Import Services",
    },
    {
      path: "export-services",
      name: "Export Services",
    },
  ];

  return (
    // <nav className="navbar sticky-top shadow navbar-expand-lg bg-body-tertiary">
    <nav className="navbar sticky-top shadow navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/images/vsmlog-logo.jpg" className="logo" alt="logo" />{" "}
          <span className="logo-name">
            <span>VSM LOGISTICS</span>
            <span className="name-hyd">HYDERABAD</span>
          </span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          data-bs-scroll="true"
          data-bs-backdrop="true"
        >
          <div className="offcanvas-header d-lg-none">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              Menu
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav flex-grow-1 justify-content-end">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-active nav-link" : "nav-link"
                  }
                  aria-current="page"
                  to="/"
                  end
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-active nav-link" : "nav-link"
                  }
                  to="/aboutus"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-active nav-link dropdown-toggle"
                      : "nav-link dropdown-toggle"
                  }
                  to="/service"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Single-Services */}
                  Our Services
                </NavLink>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "dropdown-active" : "dropdown-item"
                      }
                      to="/service/services"
                    >
                      Our Services
                    </NavLink>
                  </li>
                  {services.map((service, index) => {
                    return (
                      <li key={index}>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "dropdown-active " : "dropdown-item"
                          }
                          to={`/service/${service.path}`}
                          end
                        >
                          {service.name}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-active nav-link" : "nav-link"
                  }
                  to="/contact"
                >
                  Contact Us
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link last-link" to="/test">
                  Test
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
