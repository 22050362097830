import "./App.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import PagenotFound from "./pages/PagenotFound";
import OurApproach from "./components/aboutpage/OurApproach";
import OurGoal from "./components/aboutpage/OurGoal";
import OurMission from "./components/aboutpage/OurMission";
import OurValues from "./components/aboutpage/OurValues";
import { Route, Routes, useLocation } from "react-router-dom";
// import { Test } from "./pages/Test";
import SingleServicePage from "./pages/SingleServicePage";
import { useLayoutEffect } from "react";

function App() {
  const location = useLocation();

  // sroll position reset - scroll to top of page after a page transition.
  useLayoutEffect(() => {
    if (!location.pathname.startsWith("/aboutus/our")) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus/" element={<AboutUs />}>
          <Route path="" element={<OurApproach />} />
          <Route path="ourgoal" element={<OurGoal />} />
          <Route path="ourmission" element={<OurMission />} />
          <Route path="ourvalues" element={<OurValues />} />
        </Route>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/service/services" element={<Services />} />
        <Route path="/service/:slug" element={<SingleServicePage />} />
        {/* <Route path="/test" element={<Test />} /> */}
        <Route path="*" element={<PagenotFound />} />
      </Routes>
    </>
  );
}

export default App;
