import React from "react";
import { Link } from "react-router-dom";
import "./styles/GetInTouchPreview.css";
import { motion } from "framer-motion";

const GetInTouchPreview = () => {
  return (
    <div className="get-in-touch-preview">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-section">
            <span className="get-in-touch-subtitle subtitle">CONTACT US</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="preview-title"
            >
              Let's Connect!
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 , delay:0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="preview-text"
            >
              We’re here to make your logistics experience effortless and
              efficient. Whether you have questions or just want to chat about
              the best solutions for your business, our team is ready to help.
              Get in touch today and discover how we can support your journey!
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4,delay:0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              <Link to="/contact" className="btn btn-custom">
                Reach Out Now
              </Link>
            </motion.div>
          </div>
          <div className="col-md-6 image-section">
            <img
              src="/images/home/letsconnect.png"
              alt="Contact Us"
              className="img-fluid contact-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchPreview;
