import React from "react";
import "./styles/OurStrength.css";
import HomeButton from "../buttons/HomeButton";
import { motion } from "framer-motion";

const OurStrength = () => {
  const scrollToMethods = () => {
    document
      .getElementById("ourMethods") // id of our-methods section
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="our-strength-section" id="ourStrength">
      <div className="container">
        <div className="strength-content align-items-center">
          {/* Left Side with Cards */}
          <div className="left-content">
            <div className="grid-layout">
              <div className="grid-layout1">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  viewport={{
                    once: true,
                    amount: 0.3,
                  }}
                  className="stat-card"
                >
                  <div className="stat-body">
                    <h3 className="stat-title">
                      100<span className="stat-symbol">%</span>
                    </h3>
                    <p className="stat-text">Client Satisfaction</p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  viewport={{
                    once: true,
                    amount: 0.3,
                  }}
                  className="stat-card"
                >
                  <div className="stat-body">
                    <h3 className="stat-title">
                      <span className="stat-symbol">+</span>30
                    </h3>
                    <p className="stat-text">Clients Worldwide</p>
                  </div>
                </motion.div>
              </div>

              <div className="grid-layout2">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  viewport={{
                    once: true,
                    amount: 0.3,
                  }}
                  className="stat-card"
                >
                  <div className="stat-body">
                    <h3 className="stat-title">
                      <span className="stat-symbol">+</span>15
                    </h3>
                    <p className="stat-text">Team Members</p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  viewport={{
                    once: true,
                    amount: 0.3,
                  }}
                  className="stat-card"
                >
                  <div className="stat-body">
                    <h3 className="stat-title">
                      <span className="stat-symbol">+</span>10Y
                    </h3>
                    <p className="stat-text">Years of Experience</p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>

          {/* Right Side Content */}
          <div className="right-content text-center text-lg-start">
            <span className="strength-subtitle">OUR STRENGTH</span>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="strength-heading"
            >
              Driving <span className="our-success">Success</span> Through
              Expertise
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="strength-description"
            >
              Our team's dedication and expertise have enabled us to achieve a
              perfect record of client satisfaction. With an expanding
              international reach and trusted partnerships, we pride ourselves
              on delivering excellence in logistics solutions.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="stat-buttons"
            >
              <HomeButton path={"/contact"}>Contact Us</HomeButton>
              <button className="stat-more-button" onClick={scrollToMethods}>
                Our Methods <span className="down-arrow">&darr;</span>
              </button>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStrength;
