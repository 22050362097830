import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const SingleServiceHero = ({ params }) => {
  const [heroData, setHeroData] = useState({});

  const mainServices = [
    {
      path: "air-customs-clearance",
      name: "Air Customs Clearance",
      description:
        "Our air customs clearance services ensure a smooth and efficient process, minimizing delays and ensuring your shipments clear customs quickly and accurately.",
      backgroundImageUrl: "/images/ourservices/ocean-pic.jpg",
      iconUrl: "/images/ourservices/airplane-icon2.jpg",
    },
    {
      path: "ocean-customs-clearance",
      name: "Ocean Customs Clearance",
      description:
        "Efficient ocean customs clearance solutions that expedite your maritime shipments and keep your supply chain moving smoothly.",
      backgroundImageUrl: "/images/ourservices/ocean-pic.jpg",
      iconUrl: "/images/ourservices/ship-icon.jpg",
    },
    {
      path: "land-customs-clearance",
      name: "Land Customs Clearance",
      description:
        "Streamlined land customs clearance to ensure your overland shipments are processed quickly and without complications.",
      backgroundImageUrl: "/images/ourservices/land-pic.jpg",
      iconUrl: "/images/why-us/fast-delivery.webp",
    },
    {
      path: "train-customs-clearance",
      name: "Train Customs Clearance",
      description:
        "Reliable train customs clearance services that keep your rail shipments on track and moving through customs efficiently.",
      backgroundImageUrl: "/images/ourservices/train-pic.jpg",
      iconUrl: "/images/ourservices/train-cargo-icon.jpg",
    },
    {
      path: "air-and-ocean-freight",
      name: "Air & Ocean Freight",
      description:
        "Connecting your business to the world with reliable air and ocean freight solutions.",
      backgroundImageUrl: "/images/ourservices/air-pic.jpg",
      iconUrl: "/images/ourservices/air-ocean-icon.jpg",
    },
    {
      path: "transportation",
      name: "Transportation",
      description:
        "Comprehensive transportation services to ensure your goods are delivered safely and on time, every time.",
      backgroundImageUrl: "/images/ourservices/air-pic.jpg",
      iconUrl: "/images/why-us/fast-delivery.webp",
    },
    {
      path: "import-services",
      name: "Import Services",
      description:
        "Streamlining your global supply chain with precision, ensuring that your goods cross borders effortlessly and reach you on time, every time.",
      backgroundImageUrl: "/images/ourservices/air-ocean-freight.jpg",
      iconUrl: "/images/ourservices/imports-icon2.jpg",
    },
    {
      path: "export-services",
      name: "Export Services",
      description:
        "Empowering your business to reach new markets, our export services deliver your products to the world with reliability and speed.",
      backgroundImageUrl: "/images/ourservices/air-ocean-freight.jpg",
      iconUrl: "/images/ourservices/exports-icon.jpg",
    },
  ];

  useEffect(() => {
    const service = mainServices.find(
      (service) => service.path === params.slug
    );
    if (service) {
      setHeroData(service);
    } else {
      setHeroData({});
    }
    // eslint-disable-next-line
  }, [params.slug]);

  return (
    <div className="single-services-top-bg">
      <div className="container">
        <div className="single-services-top">
          <div className="single-service-content">
            <img src={heroData?.iconUrl} alt={`service-icon`} />
            <motion.h1
              key={`${params.slug}-3`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
              className="single-services-title"
            >
              {heroData?.name}
            </motion.h1>
            <motion.p
              key={`${params.slug}-4`}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              {heroData?.description}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleServiceHero;
