import React, { useEffect, useState } from "react";
import HomeButton from "../buttons/HomeButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const SingleServiceContent = ({ params }) => {
  const [contentData, setContentData] = useState({});
  const navigate = useNavigate();

  const services = [
    {
      path: "air-customs-clearance",
      name: "Air Customs Clearance",
      para1:
        "Our air customs clearance services ensure a smooth, efficient, and hassle-free process, minimizing delays and ensuring your shipments clear customs swiftly and accurately. Whether you're importing or exporting, we manage the full spectrum of air freight documentation and regulations, providing you with the peace of mind that your goods will move through customs seamlessly.",
      para2:
        "With a dedicated team of experts and highly streamlined procedures, we handle the complexities of air freight customs clearance, including tariff classification, duty calculation, and compliance with both local and international regulations. This allows your business to remain focused on its core operations, while we take care of every aspect of the customs process for fast, efficient delivery.",
      details: [
        "Fast processing and clearance",
        "Expert handling of customs documentation",
        "Compliance with international regulations",
      ],
      para3:
        "Our service includes thorough preparation and submission of all required customs documents, real-time tracking of your shipment, and prompt resolution of any issues that may arise during the clearance process. We also provide consultation on regulatory changes to ensure your goods meet all necessary customs requirements, helping you avoid unexpected delays and additional costs.",
      topImageUrl: "/images/ourservices/air-cc.jpg",
      bottomImageUrl: "/images/ourservices/air-cc2.jpg",
    },
    {
      path: "ocean-customs-clearance",
      name: "Ocean Customs Clearance",
      para1:
        "Our ocean customs clearance services provide efficient and effective solutions for your maritime shipments, ensuring that your cargo moves smoothly through customs and your supply chain remains uninterrupted. We manage the entire customs process to prevent delays and facilitate the seamless transit of your goods across international waters.",
      para2:
        "Our experienced team takes care of all customs procedures for ocean freight, including documentation, tariff classification, and compliance with global shipping regulations. By maintaining a proactive approach and staying current with regulatory changes, we ensure that your shipments are processed timely and in accordance with all international standards.",
      details: [
        "Streamlined documentation processing",
        "Timely handling of customs procedures",
        "Regulatory compliance for international shipping",
      ],
      para3:
        "We handle all aspects of the customs process for ocean freight, including thorough preparation and submission of necessary documents, and proactive resolution of any potential issues. Our goal is to ensure your cargo clears customs quickly and efficiently, minimizing delays and ensuring that your goods reach their destination as planned.",
      topImageUrl: "/images/ourservices/ocean-pic.jpg",
      bottomImageUrl: "/images/ourservices/ocean-cc2.webp",
    },
    {
      path: "land-customs-clearance",
      name: "Land Customs Clearance",
      para1:
        "Our land customs clearance service offers a streamlined approach to processing overland shipments, designed to ensure your goods move swiftly across borders with minimal delays.",
      para2:
        "We specialize in managing the complexities of land-based customs procedures, including border clearance, regulatory compliance, and documentation, to facilitate the smooth transit of your cargo through land routes.",
      details: [
        "Efficient border crossing management",
        "Detailed documentation handling and regulatory compliance",
        "Quick resolution of customs-related issues",
      ],
      para3:
        "From handling cross-border logistics to ensuring adherence to all necessary regulations, our land customs clearance service ensures your shipments are processed efficiently, allowing your goods to reach their destination on time.",
      topImageUrl: "/images/ourservices/land-cc2.jpg",
      bottomImageUrl: "/images/ourservices/land-cc.jpg",
    },
    {
      path: "train-customs-clearance",
      name: "Train Customs Clearance",
      para1:
        "Our train customs clearance service offers reliable and efficient processing for rail shipments, ensuring that your cargo navigates through customs seamlessly and stays on schedule.",
      para2:
        "We manage all aspects of customs clearance for rail transport, from handling complex documentation to ensuring compliance with regulations, to keep your shipments moving without unnecessary delays.",
      details: [
        "Thorough customs documentation management",
        "Prompt processing for timely rail shipments",
        "Expert handling of international rail regulations",
      ],
      para3:
        "Our comprehensive service includes meticulous preparation of customs paperwork and proactive management of any issues that arise, ensuring your rail cargo clears customs efficiently and arrives at its destination as planned.",
      topImageUrl: "/images/ourservices/train-pic.jpg",
      bottomImageUrl: "/images/ourservices/train-cc2.jpg",
    },
    {
      path: "air-and-ocean-freight",
      name: "Air & Ocean Freight",
      para1:
        "Our air and ocean freight services provide a comprehensive solution for global shipping, designed to optimize both speed and cost-effectiveness while ensuring reliable delivery of your cargo.",
      para2:
        "We offer expert management of air and ocean freight, including tailored shipping strategies and efficient route planning, to meet your specific needs and adhere to international shipping regulations.",
      details: [
        "Timely and reliable air and ocean freight solutions",
        "Customized shipping strategies for diverse cargo types",
        "Proficient management of customs and regulatory requirements",
      ],
      para3:
        "Our end-to-end freight services cover everything from cargo pickup to final delivery, ensuring smooth handling of your goods across various shipping routes and compliance with all relevant regulations.",
      topImageUrl: "/images/ourservices/air-cc.jpg",
      bottomImageUrl: "/images/ourservices/ocean-pic.jpg",
    },
    {
      path: "transportation",
      name: "Transportation",
      para1:
        "Our transportation services offer a complete solution for moving goods safely and efficiently, ensuring timely deliveries and reliable handling throughout the entire logistics chain.",
      para2:
        "We manage every aspect of transportation, including route planning, vehicle management, and real-time tracking, to ensure that your shipments are delivered on time and in optimal condition.",
      details: [
        "Dependable and punctual delivery services",
        "Variety of transportation options to suit different needs",
        "Real-time tracking and support for complete visibility",
      ],
      para3:
        "From planning the most efficient routes to monitoring each shipment's progress, our transportation services ensure that your goods are delivered safely and on schedule, enhancing the efficiency of your supply chain.",
      topImageUrl: "/images/ourservices/truck-transportation.jpg",
      bottomImageUrl: "/images/ourservices/truck-trans2.jpg",
    },
    {
      path: "import-services",
      name: "Import Services",
      para1:
        "Our import services simplify the complexities of bringing goods into your business, focusing on smooth customs clearance and efficient logistics to ensure timely and compliant delivery.",
      para2:
        "We handle every aspect of import logistics, from the initial documentation to the final delivery, managing all regulatory requirements to keep your imports running smoothly and on schedule.",
      details: [
        "Complete customs clearance and regulatory compliance",
        "Tailored logistics solutions for various import requirements",
        "Effective coordination of transportation and storage",
      ],
      para3:
        "With our expert import services, you can rely on timely, compliant delivery of your goods, supported by efficient logistics and thorough management of all necessary procedures.",
      topImageUrl: "/images/ourservices/imports-img.jpeg",
      bottomImageUrl: "/images/ourservices/air-cc.jpg",
    },
    {
      path: "export-services",
      name: "Export Services",
      para1:
        "Our export services facilitate the smooth transition of your products to international markets, ensuring secure handling and timely delivery to expand your global reach.",
      para2:
        "We offer comprehensive support for all your export needs, including documentation preparation, regulatory compliance, and logistics management, making it easier for your business to navigate international trade.",
      details: [
        "Expert handling of export documentation and regulatory compliance",
        "Custom solutions tailored to different product categories",
        "Reliable global logistics and shipping coordination",
      ],
      para3:
        "Our export services provide end-to-end support, ensuring your products are efficiently managed and delivered to international markets with all necessary compliance and documentation in place.",
      topImageUrl: "/images/ourservices/exports-img.jpeg",
      bottomImageUrl: "/images/ourservices/train-cc2.jpg",
    },
  ];

  useEffect(() => {
    const service = services.find((service) => service.path === params.slug);
    if (service) {
      setContentData(service);
    } else {
      setContentData({});
    }
    // eslint-disable-next-line
  }, [params.slug]);

  return (
    <div className="container single-service">
      <div className="service-single-grid">
        {/* Dynamic-Data */}
        <div className="service-inner-container">
          <div className="single-service-top-img">
            <img src={contentData?.topImageUrl} alt="service-top-image" />
          </div>
          <div className="service-body">
            <span className="subtitle">{contentData?.name}</span>
            <motion.h2
              key={`${params.slug}-5`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              About The Service
            </motion.h2>
            <motion.div
              key={`${params.slug}-6`}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              <p className="service-para1">{contentData?.para1}</p>
              <p className="service-para2">{contentData?.para2}</p>
            </motion.div>
            <motion.ol
              key={`${params.slug}-7`}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              {contentData?.details?.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </motion.ol>
            <motion.h2
              key={`${params.slug}-8`}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.2 }}
              viewport={{
                once: true,
                amount: 0.2,
              }}
            >
              What's included?
            </motion.h2>
            <p className="service-para3">{contentData?.para3}</p>
          </div>
          <motion.div
            key={`${params.slug}-9`}
            initial={{ opacity: 0, x: -15 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            viewport={{
              once: true,
              amount: 0.3,
            }}
            className="single-service-bottom-img"
          >
            <img src={contentData?.bottomImageUrl} alt="service-bottom-image" />
          </motion.div>
        </div>

        {/* Static-Data */}
        <motion.div
          key={`${params.slug}-10`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          viewport={{
            once: true,
            amount: 0.4,
          }}
          className="single-service-quote"
        >
          <div className="service-contact-icon">
            <FontAwesomeIcon icon={faPhone} />{" "}
          </div>
          <h2>Get In Touch</h2>
          <p>
            Get in touch to find out how this service can enhance your
            operations.
          </p>
          <div className="single-quote-buttons">
            {/* <HomeButton path={"/quotepage"}>Get A Quote</HomeButton> */}
            <HomeButton path={"/contact"}>Get A Quote</HomeButton>
            <span className="single-quote-or">or</span>
            <button
              type="button"
              className="btn btn-outline-primary single-quote-contact-button"
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SingleServiceContent;
