import React from "react";
import "./styles/GetAQuote.css";
import HomeButton from "../buttons/HomeButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const GetAQuote = () => {
  const navigate = useNavigate();

  return (
    <section className="get-a-quote-section">
      <div className="container">
        <div className="quote-inner-content">
          <span className="quote-subtitle">GET A QUOTE</span>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{
              once: true,
              amount: 0.2,
            }}
          >
            Unlock your <span className="subtitle-tag">personalized</span> quote
            today
          </motion.h2>
          <div className="row">
            <div className="col-md-7 quote-container">
              <motion.p
                initial={{ opacity: 0, y: 15 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.2 }}
                viewport={{
                  once: true,
                  amount: 0.2,
                }}
              >
                Unlocking your personalized quote is quick and easy. Simply
                provide your details, and we’ll deliver a tailored quote that
                meets your specific needs. Get started today to see how we can
                best serve you.
              </motion.p>
              <div className="quote-buttons">
                <motion.div
                  initial={{ opacity: 0, y: 15 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                >
                  {/* <HomeButton path={"/quotepage"}>Get A Quote</HomeButton> */}
                  <HomeButton path={"/contact"}>Get A Quote</HomeButton>
                </motion.div>
                <span className="quote-or">or</span>
                <motion.button
                  initial={{ opacity: 0, x: 15 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4, delay: 0.3 }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                  type="button"
                  className="btn btn-outline-primary quote-contact-button"
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </motion.button>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: -20, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ duration: 0.4, delay: 0.4 }}
              viewport={{
                once: true,
                amount: 0.4,
              }}
              className="col-md-5 quote-img"
            >
              {/* svg img */}
              <img src="/images/ourservices/quote-truck.png" alt="get-a-quote" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetAQuote;
