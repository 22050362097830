import React from "react";
import "./styles/OurFounder.css";
// import HomeButton from "./HomeButton";
import { motion } from "framer-motion";

const OurFounder = () => {
  return (
    <div className="founder-section container">
      <div className="founder-grid-body">
        <div className="text-content">
          <h3 className="subtitle">Our Founder</h3>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{
              once: true,
              amount: 0.2,
            }}
            className="quote"
          >
            Shaping the <span className="subtitle-tag">future</span> of
            logistics with <span className="subtitle-tag">passion</span>.
          </motion.h2>
          <p className="description">
            PardhaSaradhi Velicheti, our visionary founder, has over 20 years of experience in
            the logistics industry. His commitment to innovation and excellence
            has driven our company to new heights, ensuring that we always
            deliver on our promises with efficiency and integrity.
          </p>
          {/* <HomeButton path={"/services"}>Explore Our Services</HomeButton> */}
        </div>
        <div className="image-content">
          <div className="img-body">
            <img
              src="/vsm-founder.jpg"
              alt="Our Founder"
              className="founder-image img-fluid"
            />
            <div className="founder-roles">
              <span>V. PardhaSaradhi</span>
              {/* <span>
                <span style={{ fontWeight: "bold" }}>Roles: </span>Founder &
                Director
              </span> */}
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, x: 50, y: -50 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            viewport={{
              once: true,
              amount: 0.5,
            }}
            className="subimg-truck"
          >
            <img src="/images/ourteam/truck-svg.png" alt="truck-svg" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurFounder;
