import React, { useState, useEffect } from "react";
import "./styles/HomeScreenSlide.css";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

const images = [
  {
    src: "/images/vsmlog-bg-main.webp",
    subtitle: "Your Trusted Logistics Partner.",
    heading: "VSM LOGISTICS",
    description:
      "We simplify customs clearance, handling all the details so your shipments cross borders quickly and without hassle.",
    path: "/aboutus",
    pathContent: "Explore More",
  },
  {
    src: "/images/ourservices/ocean-cc2.webp",
    subtitle: "Simplifying logistics for a smoother journey.",
    heading: "Connecting your world with logistics.",
    description:
      "We streamline the customs process, ensuring your goods reach their destination without delays.",
    path: "/contact",
    pathContent: "Get A Quote",
  },
];

const HomeScreenSlide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const controls = useAnimation();

  const handleHoverStart = () => {
    controls.start({ scaleX: 25 });
  };

  const handleHoverEnd = () => {
    controls.start({ scaleX: 1 });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 10000); // 10-second interval for slide transition
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero-section position-relative">
      <div className="carousel-inner">
        <AnimatePresence initial={false}>
          {images.map(
            (image, index) =>
              index === currentSlide && (
                <motion.div
                  key={index}
                  className="carousel-item active"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0.1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                  loading="lazy"
                    src={image.src}
                    className="d-block w-100"
                    alt={`Slide ${index + 1}`}
                  />
                  <div className="content-wrapper">
                    <div className="subtitle-wrapper">
                      <p>{image.subtitle}</p>
                    </div>
                    <h1>{image.heading}</h1>
                    <p>{image.description}</p>
                    <motion.div
                      whileHover={handleHoverStart}
                      onHoverEnd={handleHoverEnd}
                      className="home-banner-button"
                    >
                      <Link to={image.path} className="explore-button">
                        <span>{image.pathContent}</span>
                        <motion.div
                          initial={{ scaleX: 1 }}
                          animate={controls}
                          transition={{ duration: 0.5 }}
                          className="sub-button"
                        ></motion.div>
                      </Link>
                    </motion.div>
                  </div>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>

      {/* Pagination */}
      <div className="carousel-pagination">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default HomeScreenSlide;
