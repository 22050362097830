import React from 'react'
import './styles/footer.css'
// import OurTeam from '../components/footerlayout/OurTeam'
import MainFooter from '../components/footerlayout/MainFooter'
import OurClients from '../components/footerlayout/OurClients'
// import GetInTouchPreview from '../components/footerlayout/GetInTouchPreview'

const Footer = () => {
  return (
    <div className='footer1'>
      {/* <OurTeam /> */}
      {/* <GetInTouchPreview /> */}
      <OurClients />
      <MainFooter />
    </div>
  )
}

export default Footer